import styled from '@emotion/styled';

import { BREAKPOINTS, baseText, disabledColor } from '@zego/theme';

const H3 = styled.h3(baseText, {
  fontWeight: 700,
  fontSize: 24,
  color: disabledColor(10),
  lineHeight: '34px',
  letterSpacing: -0.5,
  [BREAKPOINTS.small]: {
    fontSize: 32,
    lineHeight: '24px',
  },
});

export default H3;
