import styled from '@emotion/styled';

import { SC_MAX_CONTENT_WIDTH, SC_OUTER_GUTTER } from '@zego/theme';

const ContentContainer = styled.div({
  maxWidth: SC_OUTER_GUTTER + SC_MAX_CONTENT_WIDTH + SC_OUTER_GUTTER,
  paddingLeft: SC_OUTER_GUTTER,
  paddingRight: SC_OUTER_GUTTER,
  margin: '0 auto',
});

export default ContentContainer;
