import getConfig from 'next/config';

function targetWidths() {
  const resolutions = [];
  let prev = 100;
  const INCREMENT_PERCENTAGE = 8;
  const MAX_SIZE = 8192;

  const ensureEven = n => 2 * Math.round(n / 2);

  resolutions.push(prev);
  while (prev <= MAX_SIZE) {
    prev *= 1 + (INCREMENT_PERCENTAGE / 100) * 2;
    resolutions.push(ensureEven(prev));
  }

  return resolutions;
}

export const availableWidths = targetWidths();

export function nextWidth(width) {
  for (let i = 0; i < availableWidths.length; i++) {
    if (width > availableWidths[i]) {
      continue;
    }
    return availableWidths[i];
  }
  return availableWidths[availableWidths.length - 1];
}

/**
 * Turn image path into Imgix url on non-development environments
 * @param {String} src image location
 */
export function getImageUrl(src) {
  const {
    publicRuntimeConfig: { ASSET_PREFIX, DEPLOY_ENV },
  } = getConfig();

  if (DEPLOY_ENV === 'development') return src;

  return src.replace(
    `${ASSET_PREFIX}/_next/static`,
    'https://zego-www-static.imgix.net',
  );
}
