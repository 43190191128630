import React from 'react';

import { primaryColor, unit } from '@zego/theme';
import { BREAKPOINTS } from '@zego/theme';

import H1 from '@zego/components/atoms/H1';

interface HeroTitleProp {
  children: React.ReactNode;
  imageWidth?: number;
}

export default function HeroTitle({
  children,
  imageWidth = 0,
  ...rest
}: HeroTitleProp): JSX.Element {
  return (
    <H1
      css={{
        borderRadius: unit(1),
        color: primaryColor(11),
        letterSpacing: unit(0.05),
        paddingBottom: unit(2),
        fontSize: 36,
        lineHeight: 1.1,
        width: '100%',
        [BREAKPOINTS.medium]: {
          fontSize: 60,
          width: `calc(110% - ${imageWidth}*512px)`,
        },
        [BREAKPOINTS.normal]: {
          width: `calc(110% - ${imageWidth}*768px)`,
        },
      }}
      {...rest}
    >
      {children}
    </H1>
  );
}
