import { useEffect, useRef, useState } from 'react';

export default function useVisibility(node, options = {}) {
  const [visible, setVisibilty] = useState({});
  const isIntersecting = useRef();

  const handleObserverUpdate = entries => {
    const ent = entries[0];

    if (isIntersecting.current !== ent.isIntersecting) {
      setVisibilty(ent);
      isIntersecting.current = ent.isIntersecting;
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserverUpdate, options);

    const element = node.current;

    if (!element) {
      return;
    }

    observer.observe(element);

    return function cleanup() {
      observer.unobserve(element);
    };
  });

  return visible;
}
