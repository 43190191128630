// TODO: If you make edits to this file you must deal with these ESLint rules
/*eslint-disable @typescript-eslint/explicit-module-boundary-types */

import React from 'react';

import View from '@zego/components/atoms/View';

export default function FlexRow({ children, ...rest }): JSX.Element {
  return (
    <View
      css={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
      }}
      {...rest}
    >
      {children}
    </View>
  );
}
