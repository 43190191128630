import React, { useEffect, useRef, useState } from 'react';
import Imgix from 'react-imgix';

import { css } from '@emotion/core';

import useRect from '@zego/hooks/useRect';
import useVisibility from '@zego/hooks/useVisibility';
import { nextWidth } from '@zego/imgixUtils';

export default function FluidImageBase({
  ratio,
  src,
  alt,
  className,
  ...props
}) {
  const ref = useRef();
  const rect = useRect(ref);
  const { isIntersecting } = useVisibility(ref);
  const [shouldDisplay, setShouldDisplay] = useState(false);
  const imageWidth = rect ? nextWidth(rect.width) : null;

  // Ensure only mounted once (when visible in viewport)
  useEffect(() => {
    if (isIntersecting) {
      setShouldDisplay(true);
    }
  }, [isIntersecting]);

  // Hide image when off screen and source changes, affecting load
  useEffect(
    () => () => {
      if (!isIntersecting) {
        setShouldDisplay(false);
      }
    },
    [src, ratio, imageWidth, isIntersecting],
  );

  return (
    <div
      ref={ref}
      css={css`
        overflow: hidden;
      `}
      className={className}
    >
      <div
        css={css`
          position: relative;
          padding-bottom: calc(100% / ${ratio});
          overflow: hidden;
        `}
      >
        {imageWidth && shouldDisplay ? (
          <Imgix
            src={src}
            width={imageWidth}
            htmlAttributes={{ alt: alt || '' }}
            imgixParams={{ ar: `${ratio}:1` }}
            css={css`
              left: 0;
              position: absolute;
              width: 100%;
              height: 100%;
            `}
            {...props}
          />
        ) : (
          <img
            alt={alt}
            src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
            css={css`
              left: 0;
              position: absolute;
              width: 100%;
              height: 100%;
            `}
            {...props}
          />
        )}
      </div>
    </div>
  );
}
